import Dom from '../utils/dom'
import YouTubePlayer from '../partials/youtube-player'
import VimeoPlayer from '../partials/vimeo-player'

class Video {
  constructor (el) {
    this.dom = new Dom(el, [
      'btnPlay',
      'embed',
      'player',
      'poster'
    ])

    this.playerType = this.dom.root.dataset.type
    this.videoID = this.dom.embed[0].id.substr(6)
    this.autoplay = this.dom.root.dataset.autoplay
    this.controls = this.dom.root.dataset.controls
    this.playback = this.dom.root.dataset.playback

    if (this.playerType === 'youtube') {
      this.player = new YouTubePlayer({
        id: this.videoID,
        autoplay: this.autoplay,
        controls: this.controls
      })
    } else if (this.playerType === 'vimeo') {
      this.player = new VimeoPlayer({
        id: this.videoID,
        autoplay: this.autoplay,
        controls: this.controls
      })
    } else {
      console.warn('video.js - no player type defined')
      return
    }

    this.player.emitter.on('ready', (event) => { this.setState('ready') })
    this.player.emitter.on('play', (event) => { this.setState('play') })
    this.player.emitter.on('pause', (event) => { this.setState('pause') })
    this.player.emitter.on('ended', (event) => { this.setState('ended') })

    // btn play
    if (this.dom.exists('btnPlay')) {
      this.dom.btnPlay[0].onclick = (event) => {
        this.play()
      }
    }

    this.setState('loading')
  }

  play () {
    this.setState('loading')
    this.player.play()
  }

  setState (state) {
    this.dom.player[0].setAttribute('data-state', state)
  }

  onPlayerReady (event) {
    this.setState('ready')
  }
}

export default Video
