const removeHashFromString = (string) => {
  if (string.substring(0, 1) === '#') {
    string = string.substring(1)
  } else if (string.substring(0, 2) === '/#') {
    string = string.substring(2)
  }
  return string
}

export default removeHashFromString
