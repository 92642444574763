// css
import '../styles/main.scss'

// vendor
import '@babel/polyfill'
import ready from 'document-ready'
import Webfontloader from 'webfontloader'
import LazyLoad from 'vanilla-lazyload'

// utils
import './utils/foreach-polyfill.js'
import Router from './utils/router'
// import ScrollInView from './utils/scroll-inview'
import detect from './utils/detect'
import resize from './utils/resize'

// partials
import Header from './partials/header'
import Nav from './partials/nav'

// modular
import Artworks from './modular/artworks'
import ArtworkThumbs from './modular/artwork-thumbs'
import ExhibitionThumbs from './modular/exhibition-thumbs'
import Hero from './modular/hero'
import Layers from './modular/layers'
import PageTitle from './modular/page-title'
import Press from './modular/press'
import TextAndImage from './modular/text-and-image'
import Video from './modular/video'

const ES6Promise = require('es6-promise')
ES6Promise.polyfill()

ready(() => {
  detect.init()
  resize.init()

  Webfontloader.load({
    custom: { families: ['favorit-regular, favorit-bold', 'whyte-regular'] },
    active: () => { init() },
    inactive: () => { init() }
  })
})

function init () {
  // Router Modules
  const modularClasses = {
    Artworks: Artworks,
    ArtworkThumbs: ArtworkThumbs,
    ExhibitionThumbs: ExhibitionThumbs,
    Hero: Hero,
    Layers: Layers,
    PageTitle: PageTitle,
    Press: Press,
    TextAndImage: TextAndImage,
    Video: Video
  }

  const router = new Router(modularClasses, { dataID: 'data-js' }) // eslint-disable no-unused-vars
  router.fireEvent('init')

  const nav = new Nav('.js-nav')
  nav.init()

  const header = new Header('.js-header')
  header.init()

  /* eslint-disable no-unused-vars */
  const lazyLoad = new LazyLoad({})
  /* eslint-enable no-unused-vars */

  setTimeout(() => {
    document.documentElement.setAttribute('data-ready', 'true')
  }, 200)
}
