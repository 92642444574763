import Dom from '../utils/dom'
import VanillaTilt from 'vanilla-tilt'
import * as basicScroll from 'basicscroll'

class Layers {
  constructor (el) {
    this.dom = new Dom(el, ['primaryImg', 'secondaryImg'])

    VanillaTilt.init(this.dom.primaryImg[0], {
      max: 5,
      easing: 'cubic-bezier(.165,.84,.44,1)'
    })

    VanillaTilt.init(this.dom.secondaryImg[0], {
      max: 5,
      easing: 'cubic-bezier(.165,.84,.44,1)'
    })

    basicScroll.create({
      elem: this.dom.root,
      from: 'top-middle',
      to: 'bottom-middle',
      direct: true,
      props: {
        '--transform1': { from: '0', to: '-60px' },
        '--transform2': { from: '-30px', to: '0px' }
      }
    }).start()
  }
}

export default Layers
