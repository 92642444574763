import Dom from '../utils/dom'
import Carousel from '../partials/carousel'

class Hero {
  constructor (el) {
    this.dom = new Dom(el, [
      'carousel'
    ])

    this.layout = this.dom.root.dataset.layout

    if ((this.layout === 'full' || this.layout === 'contained') && this.dom.exists('carousel')) {
      this.carousel = new Carousel(this.dom.carousel[0], { setGallerySize: false, autoPlay: true })
    }
  }
}

export default Hero
