import anime from 'animejs'
import Dom from '../utils/dom'

class PageTitle {
  constructor (el) {
    // dom
    this.dom = new Dom(el, [
      'text'
    ])

    if (this.dom.text[0]) {
      // get string from title text
      const textString = this.dom.text[0].innerText

      // split title into words
      const wordArray = textString.split(' ')

      // reset text old text container
      this.dom.text[0].innerHTML = ''
      const charArrayEls = []

      // split into words for line breaks
      wordArray.forEach((word, index) => {
        let charArray = []

        // create word element
        const wordEl = document.createElement('span')
        wordEl.className = 'pageTitle__word'
        this.dom.text[0].appendChild(wordEl)

        // split word into characters
        charArray = word.split('')
        charArray.forEach((char) => {
          const charEl = document.createElement('span')
          charEl.innerText = char
          charEl.className = 'pageTitle__char'
          charEl.style.opacity = 0
          wordEl.appendChild(charEl)
          charArrayEls.push(charEl)
        })

        // unless last word add non-breaking space between words
        if (index !== (wordArray.length - 1)) {
          const nbspEl = document.createElement('span')
          nbspEl.innerHTML = String.fromCharCode(160)
          wordEl.appendChild(nbspEl)
        }
      })

      // make container visible
      this.dom.text[0].style.opacity = 1

      // animate when ready
      anime({
        targets: charArrayEls,
        opacity: [0, 1],
        duration: 2500,
        delay: anime.stagger(30, { start: 700 }),
        easing: 'linear'
      })
    }
  }
}

export default PageTitle
