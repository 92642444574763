import anime from 'animejs'
import Dom from '../utils/dom'

class ArtworkThumbVideo {
  constructor (el) {
    // this.dom = new Dom(el, ['artworkThumbVideoPlayer'])
    this.dom = new Dom(el, [])
    this.src = this.dom.root.getAttribute('data-src')
  }

  init () {
    if (this.src) {
      this.dom.root.onmouseover = (event) => {
        // create video element
        if (!this.video) {
          this.create()
        }
        this.video.load()

        // play promise to fix dom errors
        // https://developers.google.com/web/updates/2017/06/play-request-was-interrupted

        const playPromise = this.video.play()
        if (playPromise !== undefined) {
          playPromise.then(_ => {
            // Automatic playback started!
            this.video.setAttribute('data-ready', 'true')
            this.video.style.display = 'block'
            anime({
              targets: this.video,
              opacity: 1,
              duration: 200,
              easing: 'linear',
              delay: 100
            })
          }).catch(error => { console.warn('artwork-thumb-video.js - play: ' + error) })
        }
      }

      this.dom.root.onmouseout = (event) => {
        if (this.video) {
          // play promise to fix dom errors
          // https://developers.google.com/web/updates/2017/06/play-request-was-interrupted

          const playPromise = this.video.play()
          if (playPromise !== undefined) {
            playPromise.then(_ => {
              // We can now safely pause video
              this.video.pause()
              anime({
                targets: this.video,
                opacity: 0,
                duration: 200,
                easing: 'linear',
                complete: () => {
                  this.destroy()
                }
              })
            }).catch(error => { console.warn('artwork-thumb-video.js - Pause: ' + error) })
          }
        }
      }
    }
  }

  create () {
    this.video = document.createElement('video')
    this.video.muted = true
    this.video.loop = true

    this.setAttributes(this.video, {
      src: this.src,
      type: 'video/mp4',
      playsinline: true
    })

    this.dom.root.appendChild(this.video)
  }

  destroy () {
    if (this.video) {
      this.dom.root.removeChild(this.video)
    }
    this.video = null
  }

  setAttributes (el, attrs) {
    for (var key in attrs) {
      el.setAttribute(key, attrs[key])
    }
  }
}

export default ArtworkThumbVideo
