// ROUTER
//
// Usage:
//
// const sections = {
//   'Intro': Intro,
//   'Projects': Projects,
//   'Announcements': Announcements
// }
// var router = new Router(sections)

class Router {
  constructor (sections = null, options = {}) {
    this.activeSections = []
    this.dataID = (options.dataID ? options.dataID : 'data-section-js')

    if (sections !== null) {
      this.sectionEls = document.querySelectorAll('[' + this.dataID + ']')

      for (var i = 0; i < this.sectionEls.length; i++) {
        var classNameString = this.sectionEls[i].getAttribute(this.dataID)
        var arg = this.sectionEls[i]

        for (var key in sections) {
          if (key === classNameString) {
            this.sectionEls[i].obj = new sections[key](arg)

            if (this.sectionEls[i].obj) {
              this.activeSections.push(this.sectionEls[i].obj)
            }
          }
        }
      }
    }
  }

  fireEvent (eventName) {
    for (var i = 0; i < this.activeSections.length; i++) {
      var section = this.activeSections[i]
      if (typeof section[eventName] === 'function') {
        section[eventName]()
      }
    }
  }
}

export default Router
