/* global YT */
import mitt from 'mitt'
import youtubeAPI from '../utils/youtube-api'

class YouTubePlayer {
  constructor (options = {}) {
    this.videoID = options.id
    this.autoplay = toString(options.autoplay) === 'true' ? 1 : 0
    this.controls = toString(options.controls) === 'true' ? 1 : 0
    this.origin = window.location.href
    this.ready = false
    this.emitter = mitt()

    if (youtubeAPI.ready) {
      this.init()
    } else {
      youtubeAPI.emitter.on('ready', (event) => {
        this.init()
      })
      youtubeAPI.init()
    }
  }

  init () {
    if (!this.ready) {
      this.player = new YT.Player('video-' + this.videoID, {
        width: '1600',
        height: '900',
        videoId: this.videoID,
        playerVars: {
          autoplay: this.autoplay,
          controls: this.controls,
          color: 'white',
          modestbranding: 1,
          origin: this.origin
        },
        events: {
          onReady: () => { this.emitter.emit('ready') },
          onStateChange: (event) => {
            switch (event.data) {
              case 0:
                this.emitter.emit('ended')
                break
              case 1:
                this.emitter.emit('play')
                break
              case 2:
                this.emitter.emit('pause')
            }
          }
        }
      })
      this.ready = true
    }
  }

  play () {
    if (this.ready) {
      this.player.playVideo()
    } else {
      console.warn('youtube-player.js - not ready')
    }
  }

  pause () {
    if (this.ready) {
      this.player.pauseVideo()
    }
  }

  stop () {
    if (this.ready) {
      this.player.pauseVideo()
      this.player.seekTo(0)
    }
  }
}

export default YouTubePlayer
