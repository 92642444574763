/* global Vimeo */
import mitt from 'mitt'
import vimeoAPI from '../utils/vimeo-api'

class VimeoPlayer {
  constructor (options = {}) {
    this.videoID = options.id
    this.autoplay = toString(options.autoplay) === 'true'
    this.controls = toString(options.controls) === 'true'
    this.background = toString(options.background) === 'true'
    this.ready = false
    this.emitter = mitt()

    if (vimeoAPI.ready) {
      this.init()
    } else {
      vimeoAPI.emitter.on('ready', (event) => {
        this.init()
      })
      vimeoAPI.init()
    }
  }

  init () {
    if (!this.ready) {
      this.player = new Vimeo.Player('video-' + this.videoID, {
        id: this.videoID,
        width: '1600',
        height: '900',
        autoplay: this.autoplay,
        title: this.controls,
        byline: this.controls,
        portrait: this.controls,
        color: 'ffffff'
      })
      this.player.on('loaded', () => { this.emitter.emit('ready') })
      this.player.on('play', () => { this.emitter.emit('play') })
      this.player.on('pause', () => { this.emitter.emit('pause') })
      this.player.on('ended', () => { this.emitter.emit('ended') })
      this.ready = true
    }
  }

  play () {
    if (this.ready) {
      this.player.play()
    } else {
      console.warn('vimeo-player.js - not ready')
    }
  }

  pause () {
    if (this.ready) {
      this.player.pause()
    }
  }

  stop () {
    if (this.ready) {
      this.player.pause()
      this.player.setCurrentTime(0)
    }
  }
}

export default VimeoPlayer
