import mitt from 'mitt'

const vimeoAPI = {

  emitter: mitt(),

  init () {
    this.api = window.location.protocol + '//player.vimeo.com/api/player.js'
    this.ready = false
    this.scriptAdded = false

    if (!this.ready && !this.scriptAdded) {
      this.scriptEl = document.createElement('script')
      this.scriptEl.setAttribute('src', this.api)
      this.scriptEl.setAttribute('type', 'text/javascript')

      this.scriptEl.onload = () => {
        this.emitter.emit('ready')
        this.ready = true
      }

      const firstscript = document.getElementsByTagName('script')[0]
      firstscript.parentNode.insertBefore(this.scriptEl, firstscript)

      this.scriptAdded = true
    }
  }
}

export default vimeoAPI
