import Dom from '../utils/dom'

class FilterBar {
  constructor (el) {
    this.dom = new Dom(el, [
      'btnToggle'
    ])

    this.dom.btnToggle.forEach((el, index) => {
      el.onclick = () => {
        this.toggle()
      }
    })
  }

  toggle () {
    if (this.dom.root.dataset.visible === 'true') {
      this.close()
    } else {
      this.open()
    }
  }

  open () {
    this.dom.root.setAttribute('data-visible', 'true')
  }

  close () {
    this.dom.root.setAttribute('data-visible', 'false')
  }
}

export default FilterBar
