import anime from 'animejs'
import mitt from 'mitt'
import Dom from '../utils/dom'
import offset from '../utils/offset'
import detect from '../utils/detect'
import path from '../utils/path'
import resize from '../utils/resize'

class ArtworkDetail {
  constructor (el, options = {}) {
    // dom
    this.dom = new Dom(el, [
      'detailContainer',
      'detailInner',
      'detailClose',
      'detailVideo',
      'detailImg',
      'detailText',
      'detailLinkContainer',
      'detailLink',
      'detailLinkText'
    ])

    // default options
    this.defaults = {
      mobileLightbox: false,
      rootPath: null
    }

    // merge options with defaults
    this.options = Object.assign(this.defaults, options)

    // if root path doesn't exist then get current path
    if (!this.options.rootPath) {
      this.options.rootPath = path.get().path
    }

    // emitter
    this.emitter = mitt()

    // mobile lightbox for styles
    this.dom.root.setAttribute('data-mobile-lightbox', this.options.mobileLightbox ? 'true' : 'false')

    // config
    this.current = undefined
    this.active = false
    this.animating = false

    // click bg to close
    if (this.dom.exists('detailClose')) {
      this.dom.detailClose[0].onclick = (event) => {
        this.close()
      }
    }

    // click container to close
    if (this.dom.exists('detailInner') && this.dom.exists('detailContainer')) {
      this.dom.detailInner[0].onclick = (event) => {
        if (this.dom.detailContainer[0].getAttribute('data-video-state') === 'not-ready') {
          this.playVideo()
        } else {
          this.close()
        }
      }
    }

    // resize event
    resize.emitter.on('onresize', () => { this.onResize() })
    this.onResize()
  }

  open (el, updateURL = true) {
    if (!this.animating) {
      // config
      this.animating = true
      this.active = true
      this.emitter.emit('isopen')
      this.current = el.querySelector('.js-artworkThumbLink')
      this.updatePos(this.current)

      this.dom.root.setAttribute('data-active', 'true')
      document.documentElement.setAttribute('data-artwork-detail', 'true')

      // set route
      if (updateURL) {
        const route = el.getAttribute('data-artwork-route')
        if (route) {
          path.set(route)
        }
      }

      // caption
      const text = el.querySelector('.js-artworkThumbText')
      if (text) {
        this.dom.detailText[0].innerHTML = text.innerHTML
      }

      // link
      const linkText = el.getAttribute('data-link-text')

      if (this.dom.exists('detailLink')) {
        const link = el.querySelector('.js-artworkThumbIcon[data-type="website"]')
        if (link) {
          if (linkText) {
            this.dom.detailLinkText[0].innerHTML = linkText
          }
          this.dom.detailLink[0].href = link.href
          this.dom.detailLinkContainer[0].style.display = 'block'
        } else {
          this.dom.detailLinkContainer[0].style.display = 'none'
        }
      }

      // image
      const image = el.querySelector('.js-artworkThumbImg')
      const imageSrc = image.getAttribute('data-src')
      if (imageSrc) {
        this.dom.detailImg[0].src = imageSrc
        this.dom.detailImg[0].style.display = 'block'
      } else {
        this.dom.detailImg[0].style.display = 'none'
      }

      // video
      this.unloadVideo()

      const videoSrc = el.getAttribute('data-hires-video')
      if (videoSrc) {
        this.dom.detailVideo[0].src = videoSrc
        this.dom.detailVideo[0].load()
        this.dom.detailContainer[0].setAttribute('data-video-state', 'loading')
        this.playVideo()
      } else {
        this.dom.detailVideo[0].style.display = 'none'
        this.dom.detailContainer[0].setAttribute('data-video-state', 'none')
        console.warn('video not found')
      }

      // transitions

      anime({
        targets: this.dom.detailClose[0],
        opacity: [0, 1],
        duration: 300,
        easing: 'linear'
      })

      anime({
        targets: this.dom.detailContainer[0],
        scale: detect.checkIfMobile() ? [1, 1] : [0.6666, 1],
        opacity: [0, 1],
        duration: 500,
        easing: 'easeInOutBack',
        complete: () => {
          this.animating = false
        }
      })

      anime({
        targets: this.dom.detailLink[0],
        opacity: [0, 1],
        duration: 700,
        easing: 'easeOutBack',
        delay: 200
      })
    }
  }

  playVideo () {
    this.playPromise = this.dom.detailVideo[0].play()

    // fix dom errors
    // https://developers.google.com/web/updates/2017/06/play-request-was-interrupted
    if (this.playPromise !== undefined) {
      this.playPromise.then(_ => {
        this.dom.detailVideo[0].style.display = 'block'
        this.dom.detailContainer[0].setAttribute('data-video-state', 'playing')
        // Automatic playback started!
      }).catch(error => {
        console.warn('artwork-detail.js - Play Error: ' + error)
        this.dom.detailContainer[0].setAttribute('data-video-state', 'not-ready')
      })
    }
  }

  close () {
    if (!this.animating) {
      path.set(this.options.rootPath)

      if (this.dom.detailVideo[0].src) {
        this.dom.detailVideo[0].pause()
      }

      anime({
        targets: this.dom.detailClose[0],
        opacity: 0,
        duration: 300,
        easing: 'linear'
      })

      anime({
        targets: this.dom.detailContainer[0],
        scale: detect.checkIfMobile() ? 1 : 0.6666,
        opacity: 0,
        duration: 300,
        easing: 'easeInOutBack',
        complete: () => {
          document.documentElement.setAttribute('data-artwork-detail', 'false')
          this.dom.root.setAttribute('data-active', 'false')
          this.unloadVideo()
          this.animating = false
          this.active = false
          this.emitter.emit('isclosed')
        }
      })
    }
  }

  unloadVideo () {
    if (this.dom.detailVideo[0].src) {
      // unload video https://stackoverflow.com/a/28060352
      this.dom.detailVideo[0].removeAttribute('src') // empty source
      this.dom.detailVideo[0].load()
      this.dom.detailVideo[0].style.display = 'none'
    }
  }

  updatePos (el) {
    const elWidth = el.clientWidth
    const elHeight = el.clientHeight

    const divOffset = offset(el)
    this.dom.detailContainer[0].style.top = divOffset.top + 'px'

    let thumbW
    let thumbH

    if (this.options.mobileLightbox || !detect.checkIfMobile()) {
      thumbW = elWidth * 1.5
      thumbH = elHeight * 1.5
      this.dom.detailContainer[0].style.width = thumbW + 'px'
      this.dom.detailContainer[0].style.height = thumbH + 'px'

      if (divOffset.left < 20) {
        this.dom.detailContainer[0].style.left = '20px'
      } else {
        this.dom.detailContainer[0].style.left = divOffset.left + 'px'
      }

      if (divOffset.left + elWidth > (document.body.clientWidth - 20)) {
        this.dom.detailContainer[0].style.left = (document.body.clientWidth - elWidth - 20) + 'px'
      }

      const paddingLeft = elWidth * 0.25
      const paddingTop = elHeight * 0.25
      this.dom.detailContainer[0].style.marginTop = -paddingTop + 'px'

      if ((divOffset.left < paddingLeft * 1.5)) {
        // off screen left
        this.dom.detailContainer[0].style.marginLeft = '0px'
      } else if ((divOffset.left + thumbW - paddingLeft * 0.75) > document.body.clientWidth) {
        // off screen right
        this.dom.detailContainer[0].style.marginLeft = -(paddingLeft * 2) + 'px'
      } else {
        this.dom.detailContainer[0].style.marginLeft = -paddingLeft + 'px'
      }
    } else {
      thumbW = elWidth * 1
      thumbH = elHeight * 1
      this.dom.detailContainer[0].style.left = '50%'

      this.dom.detailContainer[0].style.marginTop = -((thumbH - elHeight) / 2) + 'px'
      this.dom.detailContainer[0].style.marginLeft = -thumbW / 2 + 'px'
      this.dom.detailContainer[0].style.width = thumbW + 'px'
      this.dom.detailContainer[0].style.height = thumbH + 'px'
    }
  }

  onResize () {
    clearTimeout(this.resizeTimer)
    this.resizeTimer = setTimeout(() => {
      if (this.current) {
        this.updatePos(this.current)
      }
    }, 50)
  }
}

export default ArtworkDetail
