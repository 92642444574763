import Dom from '../utils/dom'
import ArtworkThumbSlider from '../partials/artwork-thumb-slider'

class ArtworkThumbs {
  constructor (el) {
    // dom
    this.dom = new Dom(el, [
      'artworkThumbSlider'
    ])

    if (this.dom.exists('artworkThumbSlider')) {
      this.artworkThumbSlider = new ArtworkThumbSlider(this.dom.artworkThumbSlider[0])
    }
  }
}

export default ArtworkThumbs
