import * as Headroom from 'headroom.js'
import Dom from '../utils/dom'
import Logo from '../partials/logo'

class Header {
  constructor (selector) {
    const el = document.querySelector(selector)
    this.dom = new Dom(el, [
      'logo'
    ])
    this.sticky = this.dom.root.dataset.sticky === 'true'

    if (this.dom.exists('logo')) {
      this.logo = new Logo(this.dom.logo[0])
    }
  }

  init () {
    if (this.dom.root && this.sticky) {
      const headroom = new Headroom(this.dom.root, { offset: 45 })
      headroom.init()
    }
  }
}

export default Header
