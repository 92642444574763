import Dom from '../utils/dom'
import removeHashFromString from '../utils/remove-hash-from-string'
import FilterBar from '../partials/filter-bar'
import Masonry from 'masonry-layout'

class Press {
  constructor (el) {
    this.dom = new Dom(el, [
      'btnFilter',
      'filterBar',
      'filterOption',
      'filterReset',
      'pressItem',
      'masonry'
    ])

    this.columns = this.dom.root.getAttribute('data-columns')

    if (this.dom.exists('masonry') && this.columns === '2') {
      const masonry = this.dom.masonry[0]
      this.masonry = new Masonry(masonry, {
        itemSelector: '.js-masonryItem'
      })
      masonry.setAttribute('data-ready', 'true')
    }

    if (this.dom.exists('filterBar')) {
      this.filterBar = new FilterBar(this.dom.filterBar[0])
    }

    this.currentFiltered = []

    if (this.dom.exists('filterOption')) {
      this.dom.filterOption.forEach((el, index) => {
        el.onclick = (event) => {
          this.resetFilterLinks()
          event.preventDefault()
          event.currentTarget.setAttribute('data-active', 'true')
          this.filterItems(removeHashFromString(event.currentTarget.getAttribute('href')))
        }
      })
    }

    if (this.dom.exists('filterOption')) {
      this.dom.filterReset[0].onclick = (event) => {
        this.dom.root.style.height = ''
        event.preventDefault()
        this.resetFilterLinks()
        event.currentTarget.setAttribute('data-active', 'true')
        this.resetFilters()
      }
    }
  }

  resetFilterLinks () {
    this.dom.btnFilter.forEach((el, index) => {
      el.setAttribute('data-active', 'false')
    })
  }

  filterItems (targetCategory) {
    this.resetFilters()
    if (this.dom.exists('pressItem')) {
      this.dom.pressItem.forEach((el, index) => {
        let categories = el.getAttribute('data-categories')
        if (categories) {
          categories = categories.split(',')
          categories.forEach((itemCategory, index) => {
            if (itemCategory === targetCategory) {
              this.currentFiltered.push(el)
            }
          })
        }
      })
      if (this.currentFiltered.length) {
        const oldHeight = this.dom.root.offsetHeight
        this.dom.root.setAttribute('data-filter-active', 'true')
        this.currentFiltered.forEach((el, index) => {
          el.setAttribute('data-filtered', 'true')
          el.parentElement.parentElement.setAttribute('data-filtered', 'true')
        })
        this.dom.root.style.height = oldHeight + 'px'
      }
    }
  }

  resetFilters () {
    this.dom.root.setAttribute('data-filter-active', 'false')
    this.currentFiltered.forEach((el, index) => {
      el.setAttribute('data-filtered', 'false')
      el.parentElement.parentElement.setAttribute('data-filtered', 'false')
    })
    this.currentFiltered = []
  }
}

export default Press
