import Flickity from 'flickity'
import imagesLoaded from 'imagesloaded'
import VanillaTilt from 'vanilla-tilt'
import Dom from '../utils/dom'
import resize from '../utils/resize'
import ArtworkThumbVideo from '../partials/artwork-thumb-video'
import ArtworkDetail from '../partials/artwork-detail'

class ArtworkThumbSlider {
  constructor (el) {
    // dom
    this.dom = new Dom(el, [
      'carousel',
      'thumb',
      'artworkThumbLink',
      'artworkThumbVideo',
      'detail'
    ])

    // update urls on detail option
    this.updateURLs = this.dom.root.getAttribute('data-update-urls') === 'true'

    // detail
    if (this.dom.exists('detail')) {
      this.detail = new ArtworkDetail(this.dom.detail[0], { mobileLightbox: true })
      this.detail.emitter.on('isclosed', () => { this.animate() })
    }

    // config
    this.autoplay = this.dom.root.dataset.autoplay

    // if continuous_scroll and device size is small
    // downgrade autoplay to auto_advance
    if (this.autoplay === 'continuous_scroll' && window.matchMedia('(max-width: 768px)').matches) {
      this.autoplay = 'auto_advance'
    }

    if (this.dom.exists('thumb')) {
      // flickity
      this.flickity = new Flickity(this.dom.carousel[0], {
        resize: true,
        pageDots: false,
        contain: true,
        wrapAround: true,
        prevNextButtons: false,
        freeScroll: (this.autoplay === 'continuous_scroll'),
        autoPlay: (this.autoplay === 'auto_advance')
      })

      this.flickity.on('staticClick', (event, pointer, cellElement, cellIndex) => {
        const thumb = cellElement.querySelector('.js-artworkThumb')
        this.detail.open(thumb, this.updateURLs)
        this.stop()
      })

      if (this.dom.exists('thumb')) {
        this.dom.thumb.forEach((el, index) => {
          this.bindThumbEvents(el)
        })
      }

      // if continuous scroll enabled
      // https://github.com/metafizzy/flickity/issues/77#issuecomment-416198377
      if (this.autoplay === 'continuous_scroll') {
        // Set initial position to be 0
        this.flickity.x = 0

        // Pause on hover/focus
        this.dom.carousel[0].onmouseenter = (event) => {
          this.pause()
        }

        // Unpause on mouse out / defocus
        this.dom.carousel[0].onmouseleave = (event) => {
          if (this.animating) {
            this.animate()
          }
        }

        // start the marquee animation
        this.animate()
      }

      imagesLoaded(this.dom.root, { background: '.js-thumbSliderImage' }, () => {
        this.dom.root.setAttribute('data-ready', 'true')
      })

      // resize event
      resize.emitter.on('onresize', () => { this.onResize() })
      this.onResize()
    }
  }

  animate () {
    if (this.autoplay === 'continuous_scroll') {
      this.animating = true
      this.flickity.x = this.flickity.x - 1.5

      // Settle position into the slider
      this.flickity.settle(this.flickity.x)

      // Set the requestID to the local variable
      this.requestID = window.requestAnimationFrame(this.animate.bind(this))
    }
  }

  stop () {
    this.pause()
    this.flickity.stopPlayer()
    this.animating = false
  }

  pause () {
    if (this.requestID) {
      // Cancel the animation
      window.cancelAnimationFrame(this.requestID)

      // Reset the this.requestID for the next animation.
      this.requestID = undefined
    }
  }

  bindThumbEvents (el) {
    // const thumb = el.querySelector('.js-artworkThumb')
    const thumbLink = el.querySelector('.js-artworkThumbLink')
    if (thumbLink) {
      const image = thumbLink.querySelector('img[data-src]')
      const videoEl = el.querySelector('.js-artworkThumbVideo')

      if (image) {
        image.classList.add('lazyload')
      }

      VanillaTilt.init(thumbLink, {
        scale: 0.95,
        max: 5,
        speed: 300,
        easing: 'cubic-bezier(.165,.84,.44,1)'
      })

      if (videoEl) {
        const artworkThumbVideo = new ArtworkThumbVideo(videoEl)
        artworkThumbVideo.init()
      }

      thumbLink.onclick = (event) => {
        event.preventDefault() // disable default click
      }
    }
  }

  onResize () {
    // check total width
    const totalThumbs = this.flickity.cells.length
    const thumbWidth = this.dom.thumb[0].offsetWidth
    const totalWidth = thumbWidth * (totalThumbs - 1)

    if (totalWidth < document.documentElement.clientWidth) {
      const clonedThumbs = []
      this.dom.thumb.forEach((el, index) => {
        // clone thumb
        const clonedThumb = el.cloneNode(true)

        // bind hovers and configure lazy loading
        this.bindThumbEvents(clonedThumb)

        // add to array of elements
        clonedThumbs.push(clonedThumb)
      })
      // let clonedThumb = p.cloneNode(true)
      this.flickity.append(clonedThumbs)
      this.onResize()
    }
  }
}

export default ArtworkThumbSlider
