import Dom from '../utils/dom'
import path from '../utils/path'
import detect from '../utils/detect'
import Masonry from 'masonry-layout'
import VanillaTilt from 'vanilla-tilt'
import ArtworkDetail from '../partials/artwork-detail'
import ArtworkThumbVideo from '../partials/artwork-thumb-video'
import jump from 'jump.js'

class Artworks {
  constructor (el) {
    this.dom = new Dom(el, [
      'masonry',
      'artworkThumb',
      'artworkThumbVideo',
      'detail'
    ])

    // get config from dom
    this.updateURLs = this.dom.root.getAttribute('data-update-urls') === 'true'
    this.rootPath = this.dom.root.getAttribute('data-root-path')

    // detail
    if (this.dom.exists('detail')) {
      this.detail = new ArtworkDetail(this.dom.detail[0], { rootPath: this.rootPath })
    }

    if (this.dom.exists('masonry')) {
      this.dom.masonry.forEach((masonry, index) => {
        this.masonry = new Masonry(masonry, {
          itemSelector: '.js-masonryItem',
          columnWidth: '.js-grid-size',
          horizontalOrder: true,
          percentPosition: false,
          transitionDuration: 0
        })
        masonry.setAttribute('data-ready', 'true')

        if (this.dom.exists('artworkThumb')) {
          this.dom.artworkThumb.forEach((el, index) => {
            const link = el.querySelector('.js-artworkThumbLink')

            if (link) {
              link.onclick = (event) => {
                event.preventDefault()
                this.detail.open(el, this.updateURLs)
              }
            } else {
              console.warn('artworks.js - link not found')
            }

            const image = el.querySelector('img[data-src]')
            if (image) {
              image.classList.add('lazyload')
            }

            if (!detect.checkIfMobile()) {
              VanillaTilt.init(el, {
                scale: 1.02,
                speed: 500,
                easing: 'cubic-bezier(.165,.84,.44,1)',
                gyroscope: false
              })
            }
          })
        }

        if (this.dom.exists('artworkThumbVideo')) {
          this.dom.artworkThumbVideo.forEach((el, index) => {
            const artworkThumbVideo = new ArtworkThumbVideo(el)
            artworkThumbVideo.init()
          })
        }
      })
    }

    // initialise
    const currentDirectory = path.get().path
    const matchingThumb = this.dom.root.querySelector('[data-artwork-route="' + currentDirectory + '"]')
    if (matchingThumb) {
      setTimeout(() => {
        jump(matchingThumb, {
          duration: 500,
          offset: -200,
          callback: () => {
            this.detail.open(matchingThumb, this.updateURLs)
          }
        })
      }, 200)
    }
  }
}

export default Artworks
