import Dom from '../utils/dom'

class Logo {
  constructor (el) {
    // dom
    this.dom = new Dom(el, [
      'logoHover',
      'logoLink'
    ])

    // vars
    this.frames = this.dom.logoHover.length
    this.currentFrame = 0
    this.ready = true
    this.delay = 150

    // mouse event
    this.dom.logoLink[0].onmousemove = (event) => {
      this.randomize()
    }

    // initial value
    this.randomize()
  }

  randomize () {
    if (this.ready) {
      let randomID = Math.floor(Math.random() * this.frames) + 1
      if (randomID === this.currentFrame) { randomID++ }
      if (randomID > this.frames) { randomID = 1 }
      this.dom.root.setAttribute('data-current-logo-id', randomID)
      this.currentFrame = randomID
      this.ready = false
      setTimeout(() => {
        this.ready = true
      }, this.delay)
    }
  }
}

export default Logo
