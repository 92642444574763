const detect = {
  mobileWidth: '768px',

  init () {
    this.hasTouch = this.checkForTouch()
    this.hasHover = (!this.hasTouch) // preliminary assumption only
    this.isMobile = this.checkIfMobile()

    document.documentElement.setAttribute('data-has-hover', this.hasHover)
    document.documentElement.setAttribute('data-has-touch', this.hasTouch)
    document.documentElement.setAttribute('data-is-mobile', this.isMobile)

    this.watchForHover() // check for mouseover
  },

  watchForHover () {
    // https://codeburst.io/the-only-way-to-detect-touch-with-javascript-7791a3346685
    window.addEventListener('mouseover', function onFirstHover () {
      this.hasHover = true
      document.documentElement.setAttribute('data-has-hover', this.hasHover)
      window.removeEventListener('mouseover', onFirstHover, false)
    }, false)
  },

  checkForTouch () {
    return 'ontouchstart' in document.documentElement ||
      navigator.maxTouchPoints > 0 ||
      navigator.msMaxTouchPoints > 0
  },

  checkIfMobile () {
    return window.matchMedia('(max-width:' + this.mobileWidth + ')').matches
  }

}

export default detect
