// usage:
// 1. initalise once
//    resize.init()
// 2. add functions
//    resize.emitter.on('onresize', () => { yourfunction(); })

// stackoverflow.com/a/29917130

import mitt from 'mitt'

const resize = {

  init () {
    this.emitter = mitt()
    // global trigger to call custom event
    window.onresize = () => {
      this.emitter.emit('onresize')
    }
  }
}

export default resize
