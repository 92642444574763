class Dom {
  constructor (root = document, classArray = [], prefix = 'js-') {
    this.root = root

    if (this.root) {
      this.prefix = prefix
      for (var i = 0; i < classArray.length; i++) {
        var id = classArray[i]
        this.add(id)
      }
    } else {
      console.warn('dom.js - cant construct')
    }
  }

  add (id, context = this.root) {
    var queryResults = context.getElementsByClassName(this.prefix + id)
    if (queryResults.length) {
      this[id] = Array.from(queryResults)
    } else {
      // console.warn('didnt find ' + id)
    }
  }

  exists (id) {
    return this[id] !== undefined
  }
}

export default Dom
