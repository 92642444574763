import Dom from '../utils/dom'
// import detect from '../utils/detect'
// import VanillaTilt from 'vanilla-tilt'

class ExhibitionThumbs {
  constructor (el) {
    this.dom = new Dom(el, ['responsiveImage'])

    /* if (detect.hasHover) {
      VanillaTilt.init(this.dom.responsiveImage, {
        scale: 1.05,
        speed: 500,
        easing: 'cubic-bezier(.165,.84,.44,1)'
      })
    } */
  }
}

export default ExhibitionThumbs
