// usage:
// const divOffset = offset(el)
// divOffset.top
// divOffset.left

const offset = (el) => {
  const rect = el.getBoundingClientRect()

  const scrollLeft = window.pageXOffset || document.documentElement.scrollLeft

  const scrollTop = window.pageYOffset || document.documentElement.scrollTop
  return { top: rect.top + scrollTop, left: rect.left + scrollLeft }
}

export default offset
