/**
 * Array.prototype.forEach() polyfill
 * @author Chris Ferdinandi
 * @license MIT
 */

/* eslint-disable no-extend-native */
if (!Array.prototype.forEach) {
  Array.prototype.forEach = (callback, thisArg) => {
    thisArg = thisArg || window
    for (var i = 0; i < this.length; i++) {
      callback.call(thisArg, this[i], i, this)
    }
  }
}
/* eslint-enable no-extend-native */
