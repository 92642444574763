import { createBrowserHistory } from 'history'

const path = {

  get () {
    // https://css-tricks.com/snippets/javascript/get-url-and-url-parts-in-javascript/
    const output = {}
    output.host = window.location.host
    output.path = window.location.pathname
    output.pathArray = window.location.pathname.split('/')
    output.directory = output.pathArray[1]
    output.subdirectory = output.pathArray[2]
    return output
  },

  set (fullPath) {
    // path should begin with /
    const history = createBrowserHistory()
    history.push(fullPath)
  },

  goToRoot () {
    window.location.href = '/'
  },

  getHash (includeHash = false) {
    let output = ''
    if (includeHash) {
      output = this.removeHashFromString(window.location.hash)
    } else {
      output = window.location.hash
    }

    return output
  },

  removeHashFromString (string) {
    if (string.substring(0, 1) === '#') {
      string = string.substring(1)
    }
    return string
  },

  clearHash () {
    const history = createBrowserHistory()
    const loc = window.location
    history.push(loc.pathname + loc.search)
  }
}

export default path
